/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { get } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import {
  PRODUCT_ADDITIONAL_ATTR_PREFIX,
  actionTypesForAdditionalsField,
  filterTypesForAdditionalsField,
} from 'src/app/dashboard/project/products/productsAdditionalField.model';
import { ProductModel } from '../../dashboard/project/products/product.model';
import { ColumnGroupEnum } from '../enums/data-table.enum';
import { GoogleCategoryInterface } from '../model/category.model';
import { FormApiValidationError } from '../model/errors/formApiError.model';
import { mainTableColorCellPrefix, mainTableLabelCellPrefix } from '../sem-table/enums';
import { FilterLogicOperatorEnum } from '../sem-table/filters/FilterLogicOperatorEnum';
import { FilterTypesEnum } from '../sem-table/filters/FilterTypesEnum';
import { FilterGroupModel, FilterModel } from '../sem-table/filters/models/filter.model';
import { TableChangeDataEmittedInterface } from '../sem-table/models/TableChangeDataEmitted.model';
import { TableConfigurationInterface } from '../sem-table/models/TableConfigurationInterface.model';
import { ColumnValueTypesEnum } from '../sem-table/table-display/ColumnTypesEnum';
import { SimpleColumn } from '../sem-table/table-display/columns-switch/columns/simple-column/SimpleColumn';
import { GoogleCategoryService } from './google-category.service';

export interface SemTableBodyParamsParsedInterface {
  filterGroups: FilterGroupModel[] | null;
  has_manual_changes: boolean | null;
  order: string | null;
  direction: 'asc' | 'desc' | null;
  per_page: number;
  page: number;
}

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  constructor(
    private googleCategoryService: GoogleCategoryService,
    private translateService: TranslateService,
  ) {}

  removeElementFromArray(data: any, array: any[]) {
    const arrayOfId = array.map((item) => item.id);
    const index = arrayOfId.indexOf(data.id);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  returnIndexInArray(data: any, array: any[]): number {
    return array ? array.findIndex((item) => item.id === data.id) : -1;
  }

  isInArray(data: any, array: any[]): boolean {
    return !!array.find((item) => item.id === data.id);
  }

  clearEmptyPropertiesInObject(obj: any) {
    Object.keys(obj).forEach((prop) => {
      if (!obj[prop]) {
        delete obj[prop];
      }
      return obj;
    });
  }

  isArray(data: any | any[]): boolean {
    if (Object.prototype.toString.call(data) === '[object Array]') {
      return true;
    }
    return false;
  }

  removeEmptyProperties<T extends object>(object: T): T {
    const objectClone = cloneDeep(object);
    Object.entries(objectClone).forEach(
      ([key, value]) => value == null || (value === '' && delete objectClone[key as keyof typeof objectClone]),
    );

    return object;
  }

  enumToArray(data: object, parseBy: 'key' | 'value' = 'key', isValueNumeric = false) {
    let res = [];

    if (parseBy === 'value') {
      res = Object.values(data);
    } else {
      res = Object.keys(data);
    }
    if (isValueNumeric) {
      return res.slice(res.length / 2);
    }

    return res;
  }

  // filterGroups[additional_sources] ====> filterGroups = { ...filterGroups, ...filterGroups[additional_sources] }
  flattenAdditionalSourcesFilters(filterGroups: FilterGroupModel[]) {
    return cloneDeep(filterGroups).map((group) => {
      group.filters = group.filters.map((filter) => {
        const additionalSources = cloneDeep(filter.additional_sources || {});
        delete filter.additional_sources;
        return { ...filter, ...additionalSources };
      });

      return group;
    });
  }

  parseProductFiltersForApi(filterGroups: FilterGroupModel[]): FilterGroupModel[] {
    const filtersChanged: FilterGroupModel[] = this.flattenAdditionalSourcesFilters(filterGroups);

    filtersChanged.forEach((filterGroup) =>
      filterGroup.filters.forEach((activeFilter) => {
        if (activeFilter.param === 'google_product_category') {
          activeFilter.value = this.googleCategoryService.pluckIdFromCategory(activeFilter.value as GoogleCategoryInterface);
        }
      }),
    );

    return filtersChanged;
  }

  parseQueryParamsFromTable(query: TableChangeDataEmittedInterface): HttpParams {
    const { currentPage, itemsPerPage } = query;

    return new HttpParams().set('per_page', currentPage || 1).set('page', itemsPerPage || 10);
  }

  parseBodyParamsFromTable(body: TableChangeDataEmittedInterface): SemTableBodyParamsParsedInterface {
    return {
      direction: body.sorting ? body.sorting.direction : null,
      filterGroups: body.filterGroups ? body.filterGroups : null,
      order: body.sorting ? body.sorting.columnName : null,
      page: body.currentPage,
      per_page: body.itemsPerPage,
      has_manual_changes: body.filterManualChanges ? true : null,
    };
  }

  parseProductsFromApi(products: ProductModel[]): ProductModel[] {
    products.map((product) => {
      product.created_at = product.created_at ? new Date(product.created_at) : null;
      product.updated_at = product.updated_at ? new Date(product.updated_at) : null;
      product.availability_date = product.availability_date ? new Date(product.availability_date) : null;
      product.expiration_date = product.expiration_date ? new Date(product.expiration_date) : null;
      const saleDate = product.sale_price_effective_date ? product.sale_price_effective_date.split('/') : [];

      if (saleDate[0]) {
        const startDate = new Date(saleDate[0]);
        const endDate = saleDate[1] ? new Date(saleDate[1]) : null;
        product.sale_price_effective_date = [startDate, endDate];
      } else {
        product.sale_price_effective_date = null;
      }

      // eslint-disable-next-line no-underscore-dangle
      product._inactive = product.visibility === 1 || (product.visibility === 2 && !product.in_feed);

      product.override_fields.forEach((field) => {
        (product[(mainTableLabelCellPrefix + field) as keyof typeof product] as string) = this.translateService.instant(
          'projects.products.list.mark_label_changed_manually',
        );
        (product[(mainTableColorCellPrefix + field) as keyof typeof product] as string) = 'green';
      });

      if (product.thumb_path === 'https://api.sembot.io/images/blank-image.jpg') {
        (product[`${mainTableLabelCellPrefix}thumb_path` as keyof typeof product] as string) =
          this.translateService.instant('projects.products.photo_info');
        (product[`${mainTableColorCellPrefix}thumb_path` as keyof typeof product] as string) = '#17a2b8';
      }
      return product;
    });

    return products;
  }

  parseTableConfigPerAdditionalAttributes(attributes: string[], config: TableConfigurationInterface): TableConfigurationInterface {
    const configClone = cloneDeep(config);
    attributes.map((atr) => atr);
    attributes.forEach((atr) => {
      configClone.columns[`${PRODUCT_ADDITIONAL_ATTR_PREFIX}${atr}`] = new SimpleColumn({
        ableToFilter: true,
        actionsAvailable: actionTypesForAdditionalsField,
        alias: atr,
        canBeSorted: true,
        editable: {
          activeAlways: true,
        },
        filtersAvailable: filterTypesForAdditionalsField,
        group: ColumnGroupEnum.additionalFields,
        showAliasWithoutTranslation: true,
        valueType: ColumnValueTypesEnum.text,
      });
    });

    return configClone;
  }

  defaultFilterGroup(searchText: string, paramNames: string[]): FilterGroupModel[] {
    const filters: FilterModel[] = [];
    paramNames.forEach((param) => {
      filters.push({
        symbol: FilterTypesEnum.include,
        param,
        value: searchText,
        operator: FilterLogicOperatorEnum.or,
        case_sensitive: false,
      } as FilterModel);
    });
    return [
      {
        operator: null,
        filters,
      },
    ];
  }

  returnRuleErrorMessage(
    error: FormApiValidationError,
    optionsForTable: TableConfigurationInterface,
    data: TableChangeDataEmittedInterface,
  ) {
    interface CombinedNotificationType {
      message: string;
      fields: string[];
    }
    const notifications: CombinedNotificationType[] = [];

    Object.entries(error.errors).forEach((er) => {
      if (er && er[1]) {
        const fieldName = get(data, er[0]);
        if (!optionsForTable.columns[fieldName]) {
          return;
        }
        const fieldAlias = optionsForTable.columns[fieldName].alias;
        const messages = er[1] || [];

        messages.forEach((message) => {
          const notification = notifications.find((item) => item.message === message);

          if (notification) {
            notification.fields.push(fieldAlias);
          } else {
            notifications.push({
              message,
              fields: [fieldAlias],
            });
          }
        });
      }
    });

    return notifications;
  }

  search<T>(query: string, array: T[], propertyOfObject: string | null = null): T[] {
    if (!query) {
      return array;
    }

    if (propertyOfObject) {
      return array.filter((el) => (el[propertyOfObject as keyof typeof el] as string).toLowerCase().indexOf(query.toLowerCase()) > -1);
    }

    return array.filter((el: any) => el.toLowerCase().indexOf(query.toLowerCase()) > -1);
  }

  downloadCsvFile(data: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  convertToSnakeCase<T>(obj: T): unknown {
    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertToSnakeCase(item));
    }
    if (obj !== null && typeof obj === 'object') {
      return Object.keys(obj).reduce((acc, key) => {
        const snakeKey = key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).replace(/^_/, '');
        acc[snakeKey] = this.convertToSnakeCase(obj[key as keyof typeof obj]);
        return acc;
      }, {} as Record<string, unknown>);
    }
    return obj;
  }
}
