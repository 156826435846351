import { inject, Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';
import { Breadcrumb, ChannelBreadcrumbsService } from './channel-breadcrumbs.service';

@Injectable({ providedIn: 'root' })
export class BreadcrumbsService implements OnDestroy {
  private breadcrumbsSubject = new BehaviorSubject<Breadcrumb[]>([]);
  breadcrumbs$ = this.breadcrumbsSubject.asObservable();
  private destroy$ = new Subject<void>();
  private router = inject(Router);
  channelBreadcrumbsService = inject(ChannelBreadcrumbsService);

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged(),
        takeUntil(this.destroy$),
      )
      .subscribe(() => {
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs = this.createBreadcrumbs(root);
        if (JSON.stringify(this.breadcrumbsSubject.getValue()) !== JSON.stringify(breadcrumbs)) {
          this.breadcrumbsSubject.next(breadcrumbs);
        }
      });
    const root = this.router.routerState.snapshot.root;
    const breadcrumbs = this.createBreadcrumbs(root);
    this.breadcrumbsSubject.next(breadcrumbs);
  }

  private createBreadcrumbs(route: ActivatedRouteSnapshot, url: string = '', breadcrumbs: Breadcrumb[] = []): Breadcrumb[] {
    if (!route) {
      return breadcrumbs;
    }

    const routeUrl = this.getRouteUrl(route);
    const nextUrl = this.buildNextUrl(url, routeUrl);

    if (route.data?.['breadcrumb']) {
      const breadcrumb = this.getBreadcrumb(route);

      if (this.channelBreadcrumbsService.isChannelsSpecialPath(route)) {
        breadcrumbs.push(this.channelBreadcrumbsService.createChannelsBreadcrumb(route));
      }

      breadcrumbs.push({ label: breadcrumb, url: nextUrl });
    }

    return route.firstChild ? this.createBreadcrumbs(route.firstChild, nextUrl, breadcrumbs) : breadcrumbs;
  }

  private getRouteUrl(route: ActivatedRouteSnapshot): string {
    return route.url.map((segment) => segment.path).join('/');
  }

  private buildNextUrl(baseUrl: string, routeUrl: string): string {
    return routeUrl ? `${baseUrl}/${routeUrl}` : baseUrl;
  }

  private getBreadcrumb(route: ActivatedRouteSnapshot): string {
    return typeof route.data['breadcrumb'] === 'function' ? route.data['breadcrumb'](route.params) : route.data['breadcrumb'];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
