import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export interface Breadcrumb {
  label: string;
  url: string;
  campaignName?: string;
}

export interface ChannelConfig {
  prefix: string;
  label: string;
  campaignsPath: string;
  specialPaths: string[];
}

export enum ChannelType {
  GOOGLE = 'google',
  FACEBOOK = 'facebook',
  BING = 'bing',
}

export const CHANNEL_CONFIGS: Record<ChannelType, ChannelConfig> = {
  [ChannelType.GOOGLE]: {
    prefix: 'google-',
    label: 'google',
    campaignsPath: 'google-campaigns',
    specialPaths: ['google-adgroups', 'google-search-terms', 'google-shopping-performance', 'google-keywords'],
  },
  [ChannelType.BING]: {
    prefix: 'bing-',
    label: 'bing',
    campaignsPath: 'bing-campaigns',
    specialPaths: ['bing-adgroups', 'bing-search-terms', 'bing-shopping-performance', 'bing-keywords'],
  },
  [ChannelType.FACEBOOK]: {
    prefix: 'facebook-',
    label: 'facebook',
    campaignsPath: 'facebook-campaigns',
    specialPaths: ['facebook-adgroups', 'facebook-ads', 'facebook-customers'],
  },
};

export const GENERIC_SPECIAL_PATHS = new Set(['ad-group', 'search-term', 'shopping-performance', 'keyword']);

@Injectable({ providedIn: 'root' })
export class ChannelBreadcrumbsService {
  campaignName$ = new BehaviorSubject<string>('');

  private readonly router = inject(Router);

  isChannelsSpecialPath(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig?.path || '';
    if (GENERIC_SPECIAL_PATHS.has(path)) {
      return true;
    }

    return Object.values(CHANNEL_CONFIGS).some((config) => config.specialPaths.includes(path));
  }

  getChannelType(route: ActivatedRouteSnapshot): ChannelType {
    const path = route.routeConfig?.path || '';
    for (const [type, config] of Object.entries(CHANNEL_CONFIGS)) {
      if (path.startsWith(config.prefix)) {
        return type as ChannelType;
      }
    }
    return ChannelType.GOOGLE;
  }

  createChannelsBreadcrumb(route: ActivatedRouteSnapshot): Breadcrumb {
    const channelType = this.getChannelType(route);
    const channelConfig = CHANNEL_CONFIGS[channelType];
    const channelsReturnPath = `dashboard/project/${route.params['id']}/channels/${channelConfig.campaignsPath}`;
    const absoluteUrl = this.router.serializeUrl(this.router.createUrlTree([channelsReturnPath]));

    return {
      label: channelConfig.label,
      url: absoluteUrl,
      campaignName: this.campaignName$.getValue(),
    };
  }
}
