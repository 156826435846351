<div class="title-wrapper">
  @if (backButton && backButton.route) {
    <button mat-icon-button color="secondary" (click)="onBackClick()">
      <mat-icon>chevron_left</mat-icon>
    </button>
  }

  <span class="title">{{ title }} <app-beta-label *ngIf="isBeta" [isStatic]="true"></app-beta-label></span>
</div>

<ng-content></ng-content>
